import { compact } from "lodash";

import { PackageType } from "@lib/data/schemas/packages";
import { SmartActionTemplateType } from "@lib/data/schemas/smart-action-template";
import { displayItemAmountString } from "@lib/products";

import {
  countPackageAppointments,
  getFlexiblePackageUnit,
  getPackageSubtitle,
} from "@components/Package/utils";

import pluralHelper from "./utils/pluralHelper";

type PeriodType = "months" | "weeks";

export const displayQuantityAndAmountString = (
  packageObject: PackageType
): string => {
  const isContentTypeTime = packageObject?.contentType === "time";

  if (isContentTypeTime) {
    return `${countPackageAppointments(packageObject)}`;
  }

  return `${countPackageAppointments(packageObject, undefined, "appointment")}`;
};

export const singularPeriod: { [key in PeriodType]: string } = {
  weeks: "week",
  months: "month",
};

export const getFormatPeriod = (dueOn: number, period: PeriodType): string =>
  `payment at ${pluralHelper(dueOn, singularPeriod[period])}`;

export const getPaymentPackage = (
  packageItem: PackageType,
  altHeader: string | null = "Payment inactive",
  amountOnly?: boolean
): string => {
  const appts = `${getPackageSubtitle(packageItem)}`;
  const isPaymentInactive = (packageItem?.paymentOptions?.length || 0) === 0;
  const isMultiplePaymentOptions = packageItem?.paymentOptions?.length > 1;
  const singlePaymentOption =
    packageItem?.paymentOptions?.length === 1 &&
    `${displayItemAmountString(
      packageItem.paymentOptions[0].amount / 100,
      packageItem.paymentOptions[0].currency
    )}${
      packageItem?.packageType === "flexible"
        ? `/${getFlexiblePackageUnit(packageItem)}`
        : ""
    }`;

  return compact([
    amountOnly ? "" : appts,
    isPaymentInactive && altHeader,
    !isPaymentInactive && singlePaymentOption && singlePaymentOption,
    !isPaymentInactive && isMultiplePaymentOptions && "Multiple",
  ]).join(" · ");
};

/**
 * Smart action template to be used from recurring content in packages
 * */
export const recurringPackageSmartActionTemplate: SmartActionTemplateType = {
  event: {
    timming: "onDate",
    trigger: "package-instance-recurring-due",
    type: "packageInstance",
  },
  action: {
    type: "recurring-package-instance",
    artifactType: "packageInstances",
    resourceId: "",
    method: "to-client-record",
  },
};

export const weeklyOptions = [
  { label: "Mondays", value: "monday" },
  { label: "Tuesdays", value: "tuesday" },
  { label: "Wednesdays", value: "wednesday" },
  { label: "Thursdays", value: "thursday" },
  { label: "Fridays", value: "friday" },
  { label: "Saturdays", value: "saturday" },
  { label: "Sundays", value: "sunday" },
];

export const weekdays = weeklyOptions.map((day) => day.value);

/**
 * Calculate the diff days given two week days
 * */
export type WeekdayType =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";
export const countDiffDays = (
  fromWeekday: WeekdayType,
  toWeekday: WeekdayType
) => {
  const fromWeekdayIndex = weekdays.indexOf(fromWeekday);
  const toWeekdayIndex = weekdays.indexOf(toWeekday);
  const diff = toWeekdayIndex - fromWeekdayIndex;
  return diff <= 0 ? 7 + diff : diff;
};
